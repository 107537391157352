<template>
  <div :class="{ 'checked-option': checked }" class="a-student-questionnaire-option d-flex align-items-center rounded p-3" @click="$emit('click')">
    <div 
      :class="{ 'checked': checked }"
      class="a-student-questionnaire-option__checkmark rounded-circle mr-3"
    ></div>
    {{ option.name }}
  </div>
</template>

<script>
  export default {
    props: {
      option: {
        type: Object,
        required: true
      },
      checked: {
        type: Boolean,
        required: true,
        default: false
      }
    } 
  }
</script>

<style lang="sass" scoped>
  .a-student-questionnaire-option
    background-color: #fff
    cursor: pointer
    border: 2px solid #fff

    &:hover    
      border-color: rgba(#76AD63, 0.1)

    &.checked-option
      border: 2px solid #76AD63
      background-color: rgba(#76AD63, 0.25) !important

    &__checkmark
      background: #E8E4DA
      width: 26px
      height: 26px

      &.checked
        background: #E8E4DA url('./img/check-input.svg') center no-repeat
</style>